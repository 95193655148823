import React from 'react';
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import { INLINES } from "@contentful/rich-text-types";
import { FormattedMessage } from "react-intl";

import Layout from '../components/layout';
import Breadcrumb from "../components/Breadcrumb";
import ContactForm from "../components/ContactForm";

const website_url = process.env.GATSBY_DOMAIN;

// make external links in rich text go to new tabs
// https://www.contentfulcommunity.com/t/add-a-target-blank-to-hyperlink-within-rich-text-content-type/2650
const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node) => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
        >
          {node.content[0].value}
        </a>
      );
    },
  },
};

const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const TipTemplate = ({data, location}) => {
  const c = data.contentfulTip;
  const locale = c.node_locale;

  return (
    <Layout data={data} location={location}>
      <article>
        <section className="container grid-lg">
          <Breadcrumb location={location} />
          <h1>{c.title}</h1>
          {c.content && <div>{documentToReactComponents(c.content.json, options)}</div>}
        </section>
        {c.relatedTerms && (
          <section className="container grid-lg">
            <h4><FormattedMessage id="relatedTerms" /></h4>
            <div className="cards">
              {c.relatedTerms.map((term, idx) => (
                <div className={`card color-5-alt`} key={idx}>
                  <Link to={`/${locale}/terms/${term.slug}`}>
                    <h5>{term.title}</h5>
                  </Link>
                </div>
              ))}
            </div>
          </section>
        )}
        {/* <ContactForm /> */}
      </article>
    </Layout>
  );
};

TipTemplate.propTypes = propTypes;

export default TipTemplate;

export const pageQuery = graphql`
         query tipQuery($id: String!) {
           site {
             siteMetadata {
               languages {
                 defaultLangKey
                 langs
               }
             }
           }
           contentfulTip(id: { eq: $id }) {
             id
             title
             node_locale
             content {
               json
             }
             relatedTerms {
               title
               slug
             }
           }
         }
       `;
